<template>
    <div>
        <!-- Footer -->
        <footer class="footer mt-3" v-if="$route.path !== '/maung-fb' && $route.path !== '/body-fb' && $route.path !== '/auth/login'">
            <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
            <div class="align-items-center justify-content-lg-between">
                <div class="col-lg-12 text-center">
                    <ul class="nav nav-footer justify-content-between">
                        <li
                            class="nav-item"
                            @click="$router.push('/home')"
                            :class="linkIsActive('/home') ? 'active' : ''"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <i class="fas fa-home mx-auto my-1"></i>
                                <div class="mx-auto mt-1">
                                    {{
                                        $store.state.language === "en"
                                            ? "Home"
                                            : "ပင်မ"
                                    }}
                                </div>
                            </div>
                        </li>
                        <!-- <li
                            class="nav-item"
                            @click="$router.push('/withdraw')"
                            :class="linkIsActive('/withdraw') ? 'active' : ''"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <i class="fas fa-money-bill mx-auto my-1"></i>
                                <div class="mx-auto mt-1">
                                    {{
                                        $store.state.language === "en"
                                            ? "Cash out"
                                            : "ငွေထုတ်ရန်"
                                    }}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            :class="linkIsActive('/deposit') ? 'active' : ''"
                            @click="$router.push('/deposit')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <i class="fas fa-coins mx-auto my-1"></i>
                                <div class="mx-auto mt-1">
                                    {{
                                        $store.state.language === "en"
                                            ? "Cash in"
                                            : "ငွေသွင်းရန်"
                                    }}
                                </div>
                            </div>
                        </li> -->
                        <li
                            class="nav-item"
                            :class="linkIsActive('/setting') ? 'active' : ''"
                            @click="$router.push('/setting')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <i class="fas fa-sliders-h mx-auto my-1"></i>
                                <div class="mx-auto mt-1">
                                    {{
                                        $store.state.language === "en"
                                            ? "Setting"
                                            : "ချိန်ညှိခြင်း"
                                    }}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            :class="linkIsActive('/contact-us') ? 'active' : ''"
                            @click="$router.push('/contact-us')"
                        >
                            <div class="d-flex flex-column banking-icons">                                
                                <i class="fas fa-phone-volume mx-auto my-1"></i>
                                <div class="mx-auto mt-1">
                                    {{
                                    $store.state.language === "en"
                                        ? "Service"
                                        : "ဝန်ဆောင်မူ"
                                }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>

        <!-- <div class="bet-container" v-if="is_live">
            <img
                src="/build/assets/img/theme/live-icon.png"
                alt=""
                @click="$router.push('/football-live-match')"
                style="width: 50px; height: 50px"
            />
        </div> -->
    </div>
</template>
<script>
import { Toast, Dialog } from 'vant';

export default {
  data() {
    return {
      is_live: false,
    };
  },
  methods: {
    /* example link = '/settings' */
    linkIsActive(link) {
      console.log(this.$route.path === link);
      if (this.$route.path === link) return true;
      return false;
    },
    async fetchFootballLive() {
      try {
        const res = await axios.get('/football-lives');
        this.is_live = res.data.data[0].is_live;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchFootballLive();
  },
};
</script>
<style scoped>
.banking-icons {
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 5px;
}
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    width: 50px;
    /* right: 0; */
    left: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.nav-item {
    /* margin-left: 10px;
    margin-right: 10px; */
    /* border: 1px solid red; */
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    min-width: 60px;
    /* height: 100px; */
}
.nav-item:hover,
.nav-item.active {
    border-radius: 5px;
    background: #ffd93c;
    color: black;
}
@media (max-width: 767.98px) {
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #471416;
        /* border-top: 2px solid black; */
    }
    .banking-icons {
        width: auto;
        height: auto;
        padding: 5px;
        border-radius: 3px;
    }
    .footer {
        padding: 8px !important;
    }
    /* .banking-icons img {
        width: 25px;
        height: 25px;
        border-radius: 3px;
    } */
}
</style>
